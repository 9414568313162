.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
  }

  span {
    margin-left: 0.5em;
    font-size: 18px;
    font-weight: bold;
    width: min-content;
  }
}
