.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  width: 100%;

  button,
  label {
    margin: 1em;
    padding: 1em;
    border-radius: 16px;
    cursor: pointer;
  }

  input {
    display: none;
  }
}
