.status {
  margin: 1em 0;
  text-transform: uppercase;
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: flex-end;

  p {
    margin: 0;
  }

  :not(span) {
    font-weight: bold;
  }

  .online {
    color: green;
  }

  .offline {
    color: red;
  }

  .button {
    margin-left: 1em;

    &.start {
      background-color: green;
    }
    &.stop {
      background-color: red;
    }

    &:disabled {
      background-color: grey;
    }
    cursor: pointer;
    border-radius: 8px;
    color: white;
    padding: 0.5em 1.25em;
    border: none;
    font-weight: bold;
  }
}
